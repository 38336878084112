* {
    font-family: Almarai;
}
::-moz-selection {
    background: #00bfbf;
    color: #fafafa;
    text-shadow: none;
}
::selection {
    background: #00bfbf;
    color: #fafafa;
    text-shadow: none;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
html {
    overflow: scroll;
    overflow-x: hidden;
    font-size: 16px;
}
body {
    transition: all 0.5s ease;
}
p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

.wave {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
}

@keyframes wave-animation {
    0% {
        transform: rotate(0)
    }

    10% {
        transform: rotate(14deg)
    }

    20% {
        transform: rotate(-8deg)
    }

    30% {
        transform: rotate(14deg)
    }

    40% {
        transform: rotate(-4deg)
    }

    50% {
        transform: rotate(10deg)
    }

    60% {
        transform: rotate(0)
    }

    to {
        transform: rotate(0)
    }
}
